<template>
  <section class="triple-icon__container">
    <TripleIcon
      v-for="(icon, index) in iconData"
      :key="icon.title + icon.imageSrc"
      :image="icon.imageSrc"
      :title="icon.title"
      :body="icon.body"
      :index="index"
    />
  </section>
</template>

<script>
import TripleIcon from "@/components/TripleIcon";

export default {
  components: { TripleIcon },

  props: {
    iconData: Array
  }
};
</script>

<style scoped>
.triple-icon__container {
  grid-column: 1 / 4;
  display: grid;
  grid-template-columns: 100%;
  gap: 24px;
}

@media screen and (min-width: 768px) {
  .triple-icon__container {
    grid-template-columns: repeat(3, calc((100% - 48px) / 3));
  }
}
</style>
