<template>
  <div class="triple-icon" ref="container">
    <div
      class="image-container z-depth-1"
      :style="{ backgroundImage: `url(${image})` }"
      ref="image"
    ></div>
    <div class="title text--z-depth-1" ref="title">
      <h3>{{ title }}</h3>
    </div>
    <div class="body text--z-depth-1" ref="body">{{ body }}</div>
  </div>
</template>

<script>
import ScrollMagic from "scrollmagic";

export default {
  props: {
    image: {
      type: String
    },
    title: {
      type: String,
      default: ""
    },
    body: {
      type: String,
      default: ""
    },
    index: Number
  },

  data: function() {
    return {
      animationController: new ScrollMagic.Controller()
    };
  },

  mounted: function() {
    this.slideEnter(this.$refs.container, this.$refs.image);
    this.slideEnter(this.$refs.container, this.$refs.title);
    this.slideEnter(this.$refs.container, this.$refs.body);
  },

  methods: {
    slideEnter(triggerElement, target, offsetPercent = 0, triggerHook = 0.8) {
      target.classList.add("slide-enters");
      target.style.transitionDelay = 0.3 * this.index + "s";
      new ScrollMagic.Scene({
        triggerElement,
        offset: (triggerElement.scrollHeight * offsetPercent) / 100,
        triggerHook,
        reverse: false
      })
        .setClassToggle(target, "slide-enters--entered")
        .addTo(this.animationController);
    }
  }
};
</script>

<style scoped>
.slide-enters {
  transform: translateY(80px);
  opacity: 0;
  transition: all 0.7s;
}

.slide-enters--entered {
  transform: none;
  opacity: 1;
}

.triple-icon {
  text-align: left;
  margin-bottom: 60px;
}

.image-container {
  background-position: center;
  background-size: cover;
  height: calc(100vw - 48px);
  max-height: 480px;
  max-width: 480px;
  margin: 0 auto;
}

.title,
.body {
  padding: 0 8px;
}

@media screen and (min-width: 768px) {
  .image-container {
    height: calc(33.3vw - 16px);
  }

  .triple-icon {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1025px) {
  .image-container {
    height: 25vw;
    max-height: 360px;
  }
}
</style>
