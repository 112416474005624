<template>
  <section class="triptych">
    <div
      class="triptych__part triptych__part-1 z-depth-1"
      :style="withBackgroundImage"
      ref="img1"
    ></div>
    <div
      class="triptych__part triptych__part-2 z-depth-1"
      :style="withBackgroundImage"
      ref="img2"
    ></div>
    <div
      class="triptych__part triptych__part-3 z-depth-1"
      :style="withBackgroundImage"
      ref="img3"
    ></div>
    <div class="page-blurb text--z-depth-1" ref="textContainer">
      <h3 ref="title">{{ title }}</h3>
      <p ref="body">{{ blurb }}</p>
    </div>
  </section>
</template>

<script>
import ScrollMagic from "scrollmagic";

export default {
  props: {
    image: {
      type: String
    },
    title: {
      type: String,
      default: ""
    },
    blurb: {
      type: String,
      default: ""
    }
  },

  data: function() {
    return {
      animationController: new ScrollMagic.Controller(),
      withBackgroundImage: {
        backgroundImage: `url(${this.image})`
      }
    };
  },

  mounted: function() {
    this.slideEnter(this.$refs.textContainer, this.$refs.title);
    this.slideEnter(this.$refs.textContainer, this.$refs.body, 50);
    this.cropEnter(
      this.$refs.img1,
      "triptych__part-1--start-clip",
      "triptych__part-1--clip"
    );
    this.cropEnter(
      this.$refs.img2,
      "triptych__part-2--start-clip",
      "triptych__part-2--clip"
    );
    this.cropEnter(
      this.$refs.img3,
      "triptych__part-3--start-clip",
      "triptych__part-3--clip"
    );
  },

  methods: {
    slideEnter(triggerElement, target, offsetPercent = 0, triggerHook = 0.8) {
      target.classList.add("slide-enters");
      new ScrollMagic.Scene({
        triggerElement,
        offset: (triggerElement.scrollHeight * offsetPercent) / 100,
        triggerHook,
        reverse: false
      })
        .setClassToggle(target, "slide-enters--entered")
        .addTo(this.animationController);
    },

    cropEnter(target, startClipDefinition, finalClipDefinition) {
      target.classList.add("crop-enters", startClipDefinition);
      new ScrollMagic.Scene({
        triggerElement: target,
        offset: 0,
        triggerHook: 1,
        reverse: false
      })
        .setClassToggle(target, finalClipDefinition)
        .addTo(this.animationController);
    }
  }
};
</script>

<style scoped>
.slide-enters {
  transform: translateY(80px);
  opacity: 0;
  transition: all 0.7s;
}

.slide-enters--entered {
  transform: none;
  opacity: 1;
}

.crop-enters {
  transition: all 1s;
  transition-delay: 0.35s;
}

.triptych {
  grid-column: 1 / -1;
  grid-row: 1 / 4;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.triptych__part {
  overflow: hidden;
  grid-row: 1 / 3;
  height: 111.8vw;
  background-size: cover;
}

.triptych__part-1 {
  grid-column: 1;
  background-position: calc(50% + 25vw);
}

.triptych__part-1--start-clip {
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

.triptych__part-1--clip {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.triptych__part-2 {
  grid-column: 2;
  background-position: calc(50% - 25vw);
}

.triptych__part-2--start-clip {
  clip-path: inset(calc(3rem + 24px) 0 0 0);
}

.triptych__part-2--clip {
  clip-path: inset(30.9vw 0 0 0);
}

.triptych__part-3 {
  display: none;
}

.triptych__part > img {
  height: 100%;
}

.page-blurb {
  grid-row: 3 / 4;
  grid-column: 1 / -1;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.page-blurb h3 {
  text-transform: uppercase;
  margin-block-end: 12px;
}

.page-blurb p {
  margin-block-end: 0;
}

@media screen and (min-width: 769px) {
  .triptych {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  }

  .triptych__part {
    grid-row: 1 / 4;
    height: 94.6vw;
  }

  .triptych__part-1 {
    background-position: calc(50% + 33.33vw);
  }

  .triptych__part-1--clip {
    clip-path: polygon(0 0, 100% 0, 100% 74vw, 0 74vw);
  }

  .triptych__part-2 {
    background-position: center;
  }

  .triptych__part-2--clip {
    clip-path: inset(20.6vw 0 20.6vw 0);
  }

  .triptych__part-3 {
    display: block;
    grid-column: 3;
    background-position: calc(50% - 33.33vw);
  }

  .triptych__part-3--start-clip {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }

  .triptych__part-3--clip {
    clip-path: polygon(0 20.58vw, 100% 20.58vw, 100% 100%, 0 100%);
  }

  .page-blurb {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    height: 20.58vw;
  }
}

@media screen and (min-width: 1025px) {
  .triptych__part {
    height: min(71.35vw, 1027px);
  }

  .triptych__part-1 {
    background-position: calc(50% + min(25vw, 360px));
  }

  .triptych__part-1--clip {
    clip-path: polygon(
      0 0,
      100% 0,
      100% min(55.9vw, 805px),
      0 min(55.9vw, 805px)
    );
  }

  .triptych__part-2--clip {
    clip-path: inset(min(15.45vw, 222px) 0 min(15.45vw, 222px) 0);
  }

  .triptych__part-3 {
    background-position: calc(50% - min(25vw, 360px));
  }

  .triptych__part-3--clip {
    clip-path: polygon(
      0 min(15.45vw, 222px),
      100% min(15.45vw, 222px),
      100% 100%,
      0 100%
    );
  }

  .page-blurb {
    height: min(15.45vw, 222px);
  }
}
</style>
