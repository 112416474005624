<template>
  <main>
    <PageTitle>{{ contentData.title[0].value }}</PageTitle>
    <ImageTriptych 
      :image="titleImage" 
      :title="contentData.field_schlagzeile[0].value" 
      :blurb="contentData.field_zusammenfassung[0].value" />

    <section ref="captionedImageSection">
      <div
        class="captioned-image z-depth-1"
        :style="{ backgroundImage: `url(${contentData.field_bild_mit_legende[0].url})` }"
        ref="captionedImage"
      ></div>
      <div class="image__caption z-depth-2" ref="caption">
        <h3>{{ contentData.field_bildlegende_titel[0].value }}</h3>
        <p>{{ contentData.field_bildlegende_koerpertext[0].value }}</p>
        <p>{{ contentData.field_bildlegende_koerpertext[1].value }}</p>
      </div>
    </section>

    <TripleIcons :iconData="tripleIconData" />
  </main>
</template>

<script>
import { httpMixin } from "@/mixins/httpMixin";
import ScrollMagic from "scrollmagic";
import PageTitle from "@/components/PageTitle";
import ImageTriptych from "@/components/ImageTriptych";
import TripleIcons from "@/components/TripleIcons";
import { incrementComponentsLoading } from "@/events";

export default {
  mixins: [httpMixin],

  components: { PageTitle, ImageTriptych, TripleIcons },

  data: function() {
    return {
      animationController: new ScrollMagic.Controller(),
      titleImage: require("@/media/33.jpg"),
      contentData: {
        title: "Architektur",
        field_schlagzeile: [{ value: "Wir holen Sie dort ab wo Sie stehen..." }],
        field_zusammenfassung: [{ value: "… und gehen den Weg zu Ihrer Traumimmobilie mit Ihnen gemeinsam. Vom Einfamilienhaus bis hin zu Verwaltungsgebäuden – vom Neubau bis hin zu Umbauten oder Sanierungen stehen wir Ihnen mit unserem erfahrenen Team zur Seite." }],
        field_bild_mit_legende: [{ value: "" }],
        field_bildlegende_titel: [{ value: "Simultan Planning" }],
        field_bildlegende_koerpertext: [
          { value: "…interdisziplinär, interaktiv, integrativ und intuitiv planen und bauen wir mit unseren Kunden. Architektur ist für uns ein Prozess den wir mit unseren Kunden gemeinsam durchlaufen. Um unseren Weg so effizient wie möglich gehen zu können, haben wir uns für innovative Methoden in Kombination mit High-End Technik entschieden." },
          { value: "Wir planen in der 3. Dimension, sodass wir bereits vor der Umsetzung sehen wo Engstellen entstehen und ihnen Zeit sowie Kosten sparen." },
        ],
        field_icon_bilder: [
          { url: "" },
          { url: "" },
          { url: "" },
        ],
        field_icon_titeln: [
          { value: "Entsmurf" },
          { value: "Visualisierung" },
          { value: "Ausführung" },
        ],
        field_icon_koerpertexte: [
          { value: "Gemeinsam erarbeiten wir die Basis. Sie geben Ihre Wünsche und Ideen vor, wir beraten und sammeln mit Ihnen alle nötigen Infos um den ersten Entwurf erstellen zu können." },
          { value: "Durch das Arbeiten in interaktiven 3D Umgebungen sind wir in der Lage verschiedenste Situationen zu testen. Bereits im Vorfeld können wir visualisieren und berechnen was Sinn macht." },
          { value: "Interdisziplinär verfeinert unser Team die eingegangen Daten aus den Sitzungen um eine perfekte Grundlage für alle Kostenschätzungen, Pläne, etc. zu schaffen." },
        ],
      }
    };
  },

  computed: {
    tripleIconData() {
      return [
        { 
          imageSrc: this.contentData.field_icon_bilder[0].url,
          title: this.contentData.field_icon_titeln[0].value,
          body: this.contentData.field_icon_koerpertexte[0].value
        },
        { 
          imageSrc: this.contentData.field_icon_bilder[1].url,
          title: this.contentData.field_icon_titeln[1].value,
          body: this.contentData.field_icon_koerpertexte[1].value
        },
        { 
          imageSrc: this.contentData.field_icon_bilder[2].url,
          title: this.contentData.field_icon_titeln[2].value,
          body: this.contentData.field_icon_koerpertexte[2].value
        },
      ];
    }
  },
  
  created: function() {
    const self = this;
    this.componentsLoading++;
    this.fetchFromAPI("content/architektur")
      .then(content => {
        self.$set(this, "contentData", content[0]);
        self.componentsLoading--;
      })
      .catch(self.handleError);
  },

  mounted: function() {
    this.slideEnter(
      this.$refs.captionedImageSection,
      this.$refs.captionedImage
    );
    this.slideEnter(this.$refs.captionedImageSection, this.$refs.caption, 50);
  },

  methods: {
    slideEnter(triggerElement, target, offsetPercent = 0, triggerHook = 0.8) {
      target.classList.add("slide-enters");
      new ScrollMagic.Scene({
        triggerElement,
        offset: (triggerElement.scrollHeight * offsetPercent) / 100,
        triggerHook,
        reverse: false
      })
        .setClassToggle(target, "slide-enters--entered")
        .addTo(this.animationController);
    }
  },

  beforeRouteLeave(to, from, leave) {
    this.$emit(incrementComponentsLoading);
    leave();
  }
};
</script>

<style scoped>
section {
  margin: 40px auto 60px;
}

.slide-enters {
  transform: translateY(80px);
  opacity: 0;
  transition: all 0.7s;
}

.slide-enters--entered {
  transform: none;
  opacity: 1;
}

.captioned-image {
  background-size: cover;
  background-position: center;
  height: 42.86vw;
  margin: 0 -24px;
}

.image__caption {
  background: none;
  box-shadow: none;
  text-align: left;
}

@media screen and (min-width: 768px) {
  section {
    margin-bottom: 140px;
  }

  .image__caption {
    background: rgba(34, 34, 34, 0.88);
    width: 66.7%;
    margin: -72px 24px 0 auto;
    position: relative;
    padding: 16px 40px 48px;
    box-sizing: border-box;
  }

  .captioned-image {
    grid-column: 1 / 4;
    height: 32.14vw;
    max-height: 463px;
  }
}

@media screen and (min-width: 1025px) {
  .captioned-image {
    height: 32.14vw;
    max-height: 463px;
    margin: 0;
  }

  .image__caption {
    margin-right: auto;
  }
}
</style>
